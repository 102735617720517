<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawerVX"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    color="sidebarColorLight"
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
    height="100vh"
  >
    <div class="d-flex justify-center align-center" style="height: 110px; width: 100%;">
      <div>
        <v-img
          v-if="!expandOnHover"
          :src="'/assets/branding/' + ((dynamicLogo === 'verify') ? 'verifyLowResDark.png' : 'DeliverLowResDark.png')"
          :alt="dynamicLogo + '.png'"
          :height="(dynamicLogo === 'verify') ? 100 : 120"
          contain
          class="mt-n3"
        />
        <img
          v-else
          :src="(dynamicLogo === 'verify') ? 'verifyChopped.png' : 'd-clamp-on-white.png'"
          :alt="dynamicLogo + '.png'"
          style="width: 40px; height: 40px"

        />
      </div>
    </div>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
      color="sidebarColorLight"
      style="overflow-y: auto;overflow-x: hidden;"
    >
      <theme-item-group
        :item="profile"
        height="100"
      />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
      style="overflow-y: auto;"
    >
      <template>
        <!-- 1 Campaigns -->
        <theme-item
          v-if="items[1].render"
          key="1"
          :item="items[1]"
          class="align-center"
        />
        <!-- 2 Screens -->
        <theme-item
          v-if='$store.state.Permissions.screenManagement'
          key="2"
          :item="items[2]"
        />
        <!-- 3 Reports -->
        <theme-item
          v-if='$store.state.Permissions.reportingUpload || $store.state.Permissions.reportingLinking || $store.state.Permissions.reportingPlayoutScheduleGenerate || $store.state.Permissions.reportEmailDigest || $store.state.Permissions.playoutTabAllCampaigns'
          key="3"
          :item="items[3]"
        />
        <!-- 4 Verification Lite -->
        <theme-item
          v-if='$store.state.Permissions.verificationLite'
          key="4"
          :item="items[4]"
        />
        <!-- 5 Stakeholders -->
        <theme-item
          v-if=' $store.state.Permissions.stakeholderManagement'
          key="5"
          :item="stakeholdersNav"
        />
        <!-- 6 Teams -->
        <theme-item
          v-if='$store.state.Permissions.teamManagement'
          key="6"
          :item="teamsNav"
          />
        <!-- 7 Contacts -->
        <theme-item
          v-if='$store.state.Permissions.contactCardManagement'
          key="7"
          :item="contactsNav"
        />
        <!-- 8 (5) Proof of Play -->
        <theme-item
          v-if='$store.state.Permissions.proofOfPlay'
          key="8"
          :item="items[5]"
        />
        <!-- 9 (6) BroadSign -->
        <theme-item
          v-if='$store.state.Permissions.broadsignManager'
          key="9"
          :item="items[6]"
        />
        <!-- 10 (7) Verify Calculator -->
        <theme-item
          v-if='$store.state.Permissions.verifyCalculator'
          key="10"
          :item="items[7]"
        />
        <!-- 11 (8) Campaign Map -->
        <theme-item
          v-if='$store.state.Permissions.campaignMapView'
          key="11"
          :item="items[8]"
        />
      </template>
    </v-list>

    <v-img
      v-if="!expandOnHover"
      src="/assets/branding/poweredByDoohDark.png" 
      style="
        position: fixed;
        left: 50%;
        bottom: 10px;
        transform: translate(-50%, -5%);
        margin: 0 auto;
      "
      width="150"
    />
  </v-navigation-drawer>
</template>

<script>
  import PermissionControllers from '@/services/controllers/Permission'
  import UserController from '@/services/controllers/User'

  // Utilities
  import { mapGetters } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dynamicLogo: 'deliver',
      displayFormatSection: false,
      displayStakeholderSection: false,
      displayCampaignSection: false,
      email: null,
      displayManageUsersSection: false,
      items: [
        {
          icon: '',
          title: '',
          to: '',
        },
        
        // {
        //   icon: 'mdi-newspaper-plus',
        //   title: 'Dashboard',
        //   to: '/dashboard/#',
        // },

        // 1 Campaigns
        {
          icon: 'mdi-newspaper-plus',
          title: 'Campaigns',
          to: '/dashboard/campaigns',
        },

        // 2 Screens
        {
          icon: 'mdi-billboard',
          title: 'Screens',
          to: '/dashboard/formats',
        },

        // 3 Reporting
        {
          icon: 'mdi-chart-bar',
          title: 'Reporting',
          to: '/dashboard/reporting/setup',
        },
        // 4 Reporting Lite
        {
          icon: 'mdi-playlist-check',
          title: 'Verification Lite',
          to: '/dashboard/verificationLite/setup',
        },
        // 5 Proof of Play
        {
          icon: 'mdi-camera',
          title: 'PoP',
          to: '/dashboard/proof-of-play',
		    },
        // 6 Broadsign
        {
          icon: 'mdi-play-outline',
          title: 'Test Suite',
          to: '/dashboard/broadsign/broadsign',
        },

        // 7 Verify
        {
          icon: 'mdi-check-outline',
          title: 'Verify',
          to: '/dashboard/verify/costing',
        },
        // 8 Map
        {
          icon: 'mdi-map',
          title: 'Map',
          to: '/dashboard/map',
        },
      ],
    }),

    computed: {
      ...mapGetters('UI', [
        'barColor', 'barImage', 'drawer'
      ]),

      drawerVX: {
        get () {
          return this.drawer
        },
        set (val) {
          this.$store.commit('UI/SET_DRAWER', val)
        },
      },
      
      computedItems () {
        return this.items.map(this.mapItem)
      },
      
      profile () {
        let children = []
        children.push(
          {
            to: 'dashboard/user/profile',
            title: this.$t('Profile'),
          },
        )
        if(this.$store.state.Permissions.emailSignatureGenerate){
          children.push(
            {
              to: 'dashboard/user/emailGenerator',
              title: this.$t('Email Signature'),
            },
          )
        } 

        // dev ** production delete/edit this code block
        // Remove the to: 'dev-menu' option completely
        if(this.$store.state.User.user.email === 'greg@dooh.com' || this.$store.state.User.user.email === 'haroon@dooh.com' || this.$store.state.User.user.email === 'alan@dooh.com' || this.$store.state.User.user.email === 'kelly@dooh.com' || this.$store.state.User.user.email == 'patrick@dooh.com') {
          children.push(
            {
              to: 'dashboard/_dev/db-shortcuts',
              title: 'DB Shortcuts',
            },
          )
        }

        if(this.$store.state.User.user.email === 'tudor@dooh.com') {
          children.push(
            {
              to: 'dashboard/_dev/icon-library',
              title: 'Icon Library',
            },
          )
        } 
        
        return {
          avatar: 'contact-placeholder.jpg',
          group: '',
          title: this.email,
          children: children
        }
      },

      stakeholdersNav() {
        if(this.$store.state.Permissions.stakeholderManagement) {
          return {
            icon: 'mdi-account-group',
            title: 'Stakeholders',
            to: '/dashboard/stakeholders'
          }
        }
        return
      },

      teamsNav() {
        if(this.$store.state.Permissions.teamManagement) {
          return {
            icon: 'mdi-account-group-outline',
            title: 'Teams',
            to: '/dashboard/teams'
          }
        }
        return
      },

      contactsNav() {
        if(this.$store.state.Permissions.contactCardManagement) {
          return {
            icon: 'mdi-account-box-outline',
            title: 'Contacts', 
            to: '/dashboard/contacts'
          }
        }
        return
      },

      calculateDynamicLogo() {
        if(window.location.href.includes('deliver')) {
          return 'deliver'
        }
        else if(window.location.href.includes('verify')) {
          return 'verify'
        }
        else {
          return 'deliver'
        }
      }
    },
    
    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    created () {
      this.userDetails()
      PermissionControllers.getBatchPermissions('(UserReadAny,ScreenRead,StakeholderRead,CampaignRead)').then((res) => {
        const search = (what) =>
          res.data.find((element) => element.name === what)
        this.items[1].render = search('CampaignRead').isAllowed
        this.items[2].render = search('ScreenRead').isAllowed
        this.items[3].render = true
        if (search('StakeholderRead').isAllowed === false) {
          for (var i = 0; i < this.items[3].children.length; i++) {
            if (this.items[3].children[i].title === 'Stakeholders') {
              this.items[3].children.splice(i, 1)
            }
          }
        }

        const oldUsers = this.items
        this.items = []
        this.items = oldUsers
      })
    },

    mounted() {
      this.dynamicLogo = this.calculateDynamicLogo
    },

    methods: {
      // Get user name
      userDetails () {
        UserController.whoAmI().then((res) => {
          this.email = res.data.contactCard.firstName + ' ' + res.data.contactCard.surname
        })
      },

      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
<style scoped>
.v-list-item {
  min-height: 20px;
  max-height: 40px;
}
.v-responsive__sizer {
  padding-bottom: 100% !important;
}
</style>
<style lang="scss">
  .v-list-item--active a {
    color: indianred !important;
    cursor: pointer !important;
  }

</style>